import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/Seo';
import NotFound from '../components/NotFound/NotFound';

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <NotFound />
    </Layout>
);

export default NotFoundPage;
