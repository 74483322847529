import React from 'react';
import Container from '../Container/Container';
import Theme from '../Theme/Theme';
import Button from '../Button/Button';
import styles from './NotFound.module.scss';

const NotFound = () => {
    return (
        <Theme>
            <Container>
                <article className={styles.container}>
                    <h1 className={styles.title}>404</h1>
                    <p>Oups, cette page n'existe pas.</p>
                    <Button.Component
                        type={Button.Type.ROUTER_LINK_AS_BUTTON}
                        theme={Button.Theme.GREEN}
                        to="/"
                        className={styles.btn}
                    >
                        Aller à la page d'accueil
                    </Button.Component>
                </article>
            </Container>
        </Theme>
    );
};

export default NotFound;
